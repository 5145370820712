import analytics from 'yoda-core-components/lib/helpers/Analytics/Analytics';
import * as IrisActionTypes from 'yoda-site-components/lib/actionTypes/IrisActionTypes';
import { SET_PAGE_CACHE } from 'yoda-app-shell/lib/actionTypes/CacheActionTypes';
import { logwebvitals } from 'yoda-site-components/lib/middlewares/WebvitalsMiddleware';
import * as DepartmentActionTypes from '../actionTypes/DepartmentPageActionTypes';
import AnalyticsActionFilter from '../analytics/AnalyticsActionFilter';
import * as HomePageActionTypes from '../actionTypes/HomePageActionTypes';

const analyticsActionFilter = new AnalyticsActionFilter();

/**
 * This function will used only for redux middleware only to track tagging based on action request
 */
const AnalyticsMiddleware = (store) => (next) => (action) => {
    // eslint-disable-line
    // Setting Coupon Page Cache in here on server side.
    /* istanbul ignore next */
    if (__SERVER__) {
        switch (action.type) {
            case IrisActionTypes.IRIS_PAGE_SET_CACHE: {
                /* check the cacheControl and set the ttl value */
                const ttlValue = action.cacheControl ? action.cacheControl : 0;

                /**
                 * Dispatching Cache Action Here
                 */
                store.dispatch({
                    type: SET_PAGE_CACHE,
                    cache: { ttl: ttlValue },
                });
                break;
            }
            default:
                break;
        }
    }
    if (!__SERVER__) {
        let digitalData = null;
        let analyticsEventName = null;

        /**
         * component can trigger an action only for tagging
         * action should contain analytics object as mandatory
         * @param analytics.type - {string}  - tagging key
         * @param analytics.JCPDL - {Object}  - required additional details for tagging event
         */
        try {
            switch (action.type) {
                case HomePageActionTypes.HOME_PAGE_LOAD_READY: {
                    // See if the page was loaded successfuly
                    const didAllContentLoad = (localStore) => {
                        const state = localStore.getState();
                        return Object.keys(state.cmData ? state.cmData : state.irisData).length > 1;
                    };

                    /* istanbul ignore next */
                    if (!didAllContentLoad(store)) {
                        const err = {
                            error: {
                                errorID: 'MICROSITE:HOME:TEMPLATE:SERVER:FAILED',
                                errorDescription: 'Page Template Load failed',
                            },
                        };
                        analytics.track('formError', err);
                    }

                    digitalData = analyticsActionFilter.getHomepageDL(store);
                    digitalData = analyticsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    logwebvitals(store, digitalData);
                    break;
                }

                case HomePageActionTypes.FINDASTORE_PAGE_LOAD_READY: {
                    digitalData = analyticsActionFilter.getFindAStoreDL(store);
                    digitalData = analyticsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    logwebvitals(store, digitalData);
                    break;
                }
                case DepartmentActionTypes.DEPARTMENT_PAGE_LOAD_READY: {
                    digitalData = analyticsActionFilter.getDepartmentDL(store);
                    digitalData = analyticsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    logwebvitals(store, digitalData);
                    break;
                }
                default:
                    break;
            }
            /* istanbul ignore next */
            if (analyticsEventName || digitalData) {
                analytics.track(analyticsEventName, digitalData);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Got error ::::::', JSON.stringify(error));
            // @TODO - this should be added into LOGGER
        }
    }

    return next(action);
};

export default AnalyticsMiddleware;
