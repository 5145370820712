import React from 'react';
import MiniPDPInterstitialAsync from 'yoda-mini-pdp/lib/components/MiniPDPInterstitial/MiniPDPInterstitialAsync';
import Loading from 'yoda-site-components/lib/components/Loading/Loading';

/* istanbul ignore next */
const renderMiniPDP = () => (
    <>
        <Loading />
        <MiniPDPInterstitialAsync />
    </>
);
export default renderMiniPDP;
