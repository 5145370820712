import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FindAStoreRouteLazy from 'yoda-site-components/lib/components/FindAStorePage/FindAStoreRouteLazy';
import { onPageReady } from '../../../actions/FindaStoreAction';

export class FindAStore extends PureComponent {
    static defaultProps = {
        actions: {},
    };

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func),
    };

    componentDidMount() {
        this.props.actions.onPageReady();
    }

    render() {
        return <FindAStoreRouteLazy />;
    }
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ onPageReady }, dispatch),
});

export default connect(() => {}, mapDispatchToProps)(FindAStore);
