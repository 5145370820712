/*
 -------------------------------
 DO NOT EDIT THIS FILE
 -------------------------------
 */

import contextFactory from 'yoda-app-shell/lib/contextFactory';
import RestClient from 'yoda-interfaces/lib/RestClient/RestClient';
import App from '../src/App';
import rootReducer from '../src/reducers';
import rootSaga from '../src/sagas';
import rootMiddleware from '../src/middlewares';
import * as micrositeConfig from '../src/config';
import localSettings from './settings';

const history = {};
const reducer = rootReducer;
const micrositeParams = {
    ...micrositeConfig,
    localSettings,
};

const context = contextFactory(
    App,
    reducer,
    rootSaga,
    history,
    RestClient,
    micrositeParams,
    rootMiddleware
);

export default context;
