if (
    // eslint-disable-next-line no-underscore-dangle
    (typeof window !== 'undefined' && !window._babelPolyfill) ||
    // eslint-disable-next-line no-underscore-dangle
    (typeof global !== 'undefined' && !global._babelPolyfill)
) {
    require('@babel/polyfill'); // eslint-disable-line
}

require('./_client.js');
