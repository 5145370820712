export const isMktFallbackRedirectEnabled = true;
export const marketingFallbackUrl = '/m/evergreen';
export const url =
    'https://test.static-jcpenney.com/integration3a/yoda-assets/mobile/home/iris-api/v3/home.json';
export const ttl = '600';
export const version = '2019.0.73';
export const root = 'https://test.static-jcpenney.com';

export const HelpSection = [
    {
        id: 'give_call',
        key: 1,
        type: 'svg',
        text: 'Give us a call',
        textStrong: '1-800-322-1189',
        linkPath: '',
        linkPathMobile: 'tel:1-800-322-1189',
        name: 'icon-phone',
    },
    {
        id: 'email_us',
        key: 2,
        type: 'svg',
        text: 'Send us an email',
        textStrong: 'support@jcp.com',
        linkPath: 'mailto:support@jcp.com',
        name: 'mail-fill',
    },
    {
        id: 'chat_us',
        key: 3,
        type: 'svg',
        text: 'Need help?',
        textStrong: 'Customer Service',
        linkPath: '/m/customer-service',
        name: 'customer-service',
    },
    {
        id: 'ask_us',
        key: 4,
        type: 'svg',
        text: 'Ask us on Twitter',
        textStrong: '@askJCP',
        linkPath: 'https://twitter.com/askjcp',
        name: 'icon-twitter',
        newTab: '_blank',
    },
];

export const ConnectWithUs = [
    {
        key: 1,
        text: 'Facebook',
        iconName: 'socialmedia-facebook',
        linkPath: 'https://www.facebook.com/jcp',
        linkPathMobile: 'https://www.facebook.com/jcp',
    },
    {
        key: 2,
        text: 'Twitter',
        iconName: 'socialmedia-twitter',
        linkPath: 'https://twitter.com/jcpenney',
        linkPathMobile: 'https://twitter.com/jcpenney',
    },
    {
        key: 3,
        text: 'Pinterest',
        iconName: 'socialmedia-pinterest',
        linkPath: 'https://www.pinterest.com/jcpenney/',
        linkPathMobile: 'https://www.pinterest.com/jcpenney/',
    },
    {
        key: 4,
        text: 'Instagram',
        iconName: 'instagram-line',
        linkPath: 'https://www.instagram.com/jcpenney',
        linkPathMobile: 'https://www.instagram.com/jcpenney',
    },
    {
        key: 5,
        text: 'Youtube',
        iconName: 'socialmedia-youtube',
        linkPath: 'https://www.youtube.com/jcpenney',
        linkPathMobile: 'https://www.youtube.com/jcpenney',
    },
    {
        key: 6,
        text: 'JCPenney Blog',
        iconName: 'socialmedia-blog',
        linkPath: 'https://www.jcpenney.com/style',
        linkPathMobile: 'https://www.jcpenney.com/style',
    },
];
