/**
 * List of constants required for ensighten tagging, we will be using on page level components to update jcpDl object
 */
const constants = {
    HOME_PAGE_ID: 'welcome',
    DEPARTMENT_PAGE_ID: 'department',
    FINDASTORE_PAGE_ID: 'find a store',
    siteSection: {
        HOME: 'home',
        DEPARTMENT: 'department',
        FINDASTORE: 'findastore',
    },
};

export default constants;
