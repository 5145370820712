/* eslint-disable react/no-redundant-should-component-update */
/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import IrisPage from 'yoda-site-components/lib/components/IrisPage/IrisPage';
import irisComponentsForHome from 'yoda-site-components/lib/iris/components/forHome';
import CMPageLazy from 'yoda-site-components/lib/components/CoreMediaPage/CMPageLazy';
import RedirectAction from 'yoda-app-shell/lib/action/RedirectAction';
import ScrollTop from 'yoda-site-components/lib/components/ScrollTop/ScrollTop';
import SeoSchema from 'yoda-site-components/lib/components/SeoSchema/SeoSchema';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { hotdealsPostProcessor } from 'yoda-interfaces/lib/IrisPage/IrisPage';
import _get from 'lodash/get';
import { selectDeviceName } from 'yoda-site-components/lib/selectors/ContextSelector';
import { isEmpty } from 'lodash';
import { getCriteoData } from 'yoda-site-components/lib/actions/CriteoAction';
import renderMiniPDP from '../../../helper/MiniPDPHelper';
import { onPageReady } from '../../../actions/HomePageAction';
import {
    isMktFallbackRedirectEnabled,
    marketingFallbackUrl,
    url,
    ttl,
    version,
    root,
    HelpSection,
    ConnectWithUs,
} from '../../../globalConstants';

const defaultPreferences = {
    renderingStrategy: {
        home: {
            limitInitialComponentsRendered: {
                mobile: 8,
                desktop: 9,
                tablet: 8,
            },
            offset: {
                mobile: 500,
                desktop: 800,
                tablet: 500,
            },
        },
    },
    enableHomeSeoSchema: true,
    isMktFallbackRedirectEnabled,
    marketingFallbackUrl,
    url,
    ttl,
    version,
    root,
};

/* istanbul ignore next */
export class HomePage extends PureComponent {
    static defaultProps = {
        irisData: {},
        cmData: {},
        location: {},
        isPreview: false,
        irisFallbackEnabled: false,
        disableIrisCall: false,
        enableCmPage: false,
        cmFallbackEnabled: false,
        cmFallbackPreferences: {},
        connectWithUs: [],
        helpSection: [],
        logoUrlLink: '',
        enableHomeSeoSchema: defaultPreferences.enableHomeSeoSchema,
        history: {},
        offset: defaultPreferences.renderingStrategy.home.offset,
        limitInitialComponentsRendered:
            defaultPreferences.renderingStrategy.home.limitInitialComponentsRendered,
        deviceName: 'mobile',
        isIrisFallBackEnabled: false,
        cmSwitchWidgetSelectedValue: '',
        enableCMSwitchWidget: false,
        enableMiniPDPFeature: true,
        enableCriteo: false,
    };

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        irisData: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ])
        ),
        location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
        isPreview: PropTypes.bool,
        irisFallbackEnabled: PropTypes.bool,
        irisFallbackPreferences: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
            .isRequired,
        disableIrisCall: PropTypes.bool,
        cmData: PropTypes.object,
        enableCmPage: PropTypes.bool,
        cmFallbackEnabled: PropTypes.bool,
        cmFallbackPreferences: PropTypes.object,
        connectWithUs: PropTypes.array,
        helpSection: PropTypes.array,
        logoUrlLink: PropTypes.string,
        enableHomeSeoSchema: PropTypes.bool,
        history: PropTypes.object,
        // when using react rendering stretigies use this props to pass height and offset to ref div
        offset: PropTypes.number,
        deviceName: PropTypes.string,
        limitInitialComponentsRendered: PropTypes.string,
        isIrisFallBackEnabled: PropTypes.bool,
        cmSwitchWidgetSelectedValue: PropTypes.string,
        enableCMSwitchWidget: PropTypes.bool,
        enableMiniPDPFeature: PropTypes.bool,
        enableCriteo: PropTypes.bool,
    };

    componentDidMount() {
        const {
            enableCmPage,
            location,
            isIrisFallBackEnabled,
            isPreview,
            enableCMSwitchWidget,
            cmSwitchWidgetSelectedValue,
            irisData,
            enableCriteo,
        } = this.props;
        const isCMTestEnabled = JSON.parse(new URLSearchParams(location?.search).get('cmTest'));
        // CM to IRIS fallback logic and analytics
        if (!isEmpty(irisData)) {
            const { analytics } = irisData;
            if (analytics) {
                const { pageType, pageName } = analytics;
                this.props.actions.onPageReady(pageName, pageType);
            }
        } else {
            const isCMPageEnabled =
                isPreview && enableCMSwitchWidget
                    ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                      !isIrisFallBackEnabled &&
                      (enableCmPage || isCMTestEnabled) &&
                      isCMTestEnabled !== false
                    : !isIrisFallBackEnabled &&
                      (enableCmPage || isCMTestEnabled) &&
                      isCMTestEnabled !== false;
            if (isCMPageEnabled) {
                const cmAnalytics = this.props.cmData?.page?.analytics;
                if (cmAnalytics) {
                    const { pageType, pageName } = cmAnalytics;
                    this.props.actions.onPageReady(pageName, pageType);
                }
            }
        }
        LocalStorage.removeData('scrollToCartridge');
        LocalStorage.removeData('galleryScrollTo');

        if (!__SERVER__ && enableCriteo) {
            this.props.actions.getCriteoData({
                pageType: 'Home',
            });
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.isIrisFallBackEnabled) {
            return true;
        }
        if (nextProps.cmSwitchWidgetSelectedValue !== this.props?.cmSwitchWidgetSelectedValue) {
            return true;
        }
        if (nextProps.irisData?.id && nextProps.irisData.id !== this.props.irisData?.id) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.irisData !== this.props.irisData) {
            const { analytics } = this.props.irisData;
            if (analytics) {
                const { pageType, pageName } = analytics;
                this.props.actions.onPageReady(pageName, pageType);
            }
        }
    }

    buildHomeSeoSchema = () => {
        const { connectWithUs, helpSection, logoUrlLink } = this.props;
        const connectWithUsLinks = [];

        connectWithUs?.map((i) => connectWithUsLinks.push(i.linkPath));

        const contactPhoneNumber = helpSection?.find((i) => i.id === 'give_call')?.textStrong || '';
        const schema = [
            {
                '@context': 'https://schema.org',
                '@graph': [
                    {
                        '@type': 'Organization',
                        url: 'https://www.jcpenney.com/',
                        name: 'JCPenney',
                        alternateName: ['JCP', "Penney's", 'Penney OpCo LLC'],
                        logo: {
                            '@type': 'ImageObject',
                            url: logoUrlLink,
                        },
                        sameAs: connectWithUsLinks,
                        contactPoint: {
                            '@type': 'ContactPoint',
                            telephone: contactPhoneNumber,
                            contactType: 'Customer Support',
                        },
                    },
                ],
            },
            {
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                url: 'https://www.jcpenney.com/',
                potentialAction: {
                    '@type': 'SearchAction',
                    target: 'https://www.jcpenney.com/s?searchTerm={search_term_string}',
                    'query-input': 'required name=search_term_string',
                },
            },
        ];

        return schema;
    };

    render() {
        let params = '/home';
        const {
            irisFallbackEnabled,
            irisFallbackPreferences,
            disableIrisCall,
            cmFallbackPreferences,
            location,
            enableCmPage,
            cmFallbackEnabled,
            history,
            limitInitialComponentsRendered,
            offset,
            deviceName,
            isIrisFallBackEnabled,
            cmSwitchWidgetSelectedValue,
            isPreview,
            enableCMSwitchWidget,
            enableHomeSeoSchema,
            enableMiniPDPFeature,
        } = this.props;
        if (this.props.isPreview) {
            params = `/home${location.search}`;
        }
        const isCMTestEnabled = JSON.parse(new URLSearchParams(location?.search).get('cmTest'));
        const renderMiniPDPComponent = enableMiniPDPFeature ? renderMiniPDP() : <></>;
        const isCMPageEnabled =
            isPreview && enableCMSwitchWidget
                ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                  !isIrisFallBackEnabled &&
                  (enableCmPage || isCMTestEnabled) &&
                  isCMTestEnabled !== false
                : !isIrisFallBackEnabled &&
                  (enableCmPage || isCMTestEnabled) &&
                  isCMTestEnabled !== false;
        const CMSPageTemplate = isCMPageEnabled ? CMPageLazy : IrisPage;
        const pageProps = {
            params,
            pageType: 'HOME',
            aggregateAPI: true,
            'data-automation-id': 'home',
            postProcessor: hotdealsPostProcessor,
            limitInitialComponentsRendered: limitInitialComponentsRendered[deviceName],
            offset: offset[deviceName],
            location,
            history,
        };
        const CMComponentProps = {
            cmFallbackEnabled,
            cmFallbackPreferences,
            isCMPageEnabled,
            redirectAction: RedirectAction.setRedirectUrl,
        };

        const IrisComponentProps = {
            irisFallbackEnabled,
            disableIrisCall,
            irisFallbackPreferences,
            irisComponents: irisComponentsForHome,
        };
        return (
            <>
                <ScrollTop />
                {enableHomeSeoSchema && (
                    <SeoSchema isInHeadTag schema={this.buildHomeSeoSchema()} />
                )}
                <CMSPageTemplate
                    {...pageProps}
                    {...(isCMPageEnabled ? CMComponentProps : IrisComponentProps)}
                />
                {renderMiniPDPComponent}
            </>
        );
    }
}

const mapStateToProps = ({ irisData, context, cmData, cmSwitchWidgetData }) => ({
    irisData,
    cmData,
    cmSwitchWidgetSelectedValue: _get(cmSwitchWidgetData, 'cmSwitchWidgetSelectedValue', ''),
    isPreview: _get(context, 'isPreview', false),
    irisFallbackPreferences: _get(
        context,
        'preferences.irisfallbackPreferences',
        defaultPreferences
    ),
    irisFallbackEnabled: _get(context, 'featureFlags.enableIrisFallback', true),
    disableIrisCall: _get(context, 'featureFlags.disableIrisCall', false),
    enableCmPage: _get(context, 'featureFlags.enableCmPage', false),
    cmFallbackEnabled: _get(context, 'featureFlags.enableIrisFallback', true),
    cmFallbackPreferences: _get(context, 'preferences.irisfallbackPreferences', defaultPreferences),
    connectWithUs: _get(context, 'preferences.footer.ConnectWithUs', ConnectWithUs),
    helpSection: _get(context, 'preferences.footer.HelpSection', HelpSection),
    logoUrlLink: _get(context, 'preferences.assets.linkPath', ''),
    enableHomeSeoSchema: _get(context, 'featureFlags.enableHomeSeoSchema', true),
    limitInitialComponentsRendered: _get(
        context,
        'preferences.renderingStrategy.home.limitInitialComponentsRendered',
        defaultPreferences.renderingStrategy.home.limitInitialComponentsRendered
    ),
    offset: _get(
        context,
        'preferences.renderingStrategy.home.offset',
        defaultPreferences.renderingStrategy.home.offset
    ),
    deviceName: selectDeviceName({ context }),
    isIrisFallBackEnabled: _get(cmData, 'isIrisFallBackEnabled', false),
    enableCMSwitchWidget: _get(context, 'featureFlags.enableCMSwitchWidget', false),
    enableMiniPDPFeature: _get(context, 'featureFlags.enableMiniPDPFeature', true),
    enableCriteo: _get(context, 'featureFlags.enableCriteo', false),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            onPageReady,
            getCriteoData,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
