import flatten from 'lodash/flatten';

export default (rowData, columns) => {
    const bottomComponentTypes = ['JCPRecoZone', 'JCPAds'];

    const newColumns = [];

    columns.forEach((col) => {
        if (col.name === 'S2') {
            const { items } = col;

            // separate out seoblock, recs, ads to it's own section from page components
            const pageComponents = items.filter(
                (component) => !bottomComponentTypes.includes(component.componentType)
            );
            newColumns.push({ ...col, items: pageComponents });

            const recComponents = items.filter(
                (component) => component.componentType === 'JCPRecoZone'
            );
            const adComponents = items.filter((component) => component.componentType === 'JCPAds');
            const seoComponent = [{ componentType: 'JCPSeoBlock' }];

            // create new placement for recs, seo, ads in order
            const bottomComponents = flatten([recComponents, seoComponent, adComponents]);

            newColumns.push({
                ...col,
                items: bottomComponents,
                name: `${col.name}-b`,
            });
        } else {
            newColumns.push(col);
        }
    });

    const newRowData = { ...rowData, placements: newColumns };
    return newRowData;
};
