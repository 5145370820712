import CommonMiddleware from 'yoda-site-components/lib/middlewares/CommonMiddleware';
import CouponMiddleware from 'yoda-site-components/lib/middlewares/CouponMiddleware';
import StyliticsMiddleware from 'yoda-site-components/lib/middlewares/StyliticsMiddleware';
import MiniPDPAnalyticsMiddleware from 'yoda-mini-pdp/lib/middlewares/MiniPDPAnalyticsMiddleware';
import AnalyticsMiddleware from './AnalyticsMiddleware';

const rootMiddleware = [
    CommonMiddleware,
    AnalyticsMiddleware,
    MiniPDPAnalyticsMiddleware,
    CouponMiddleware,
    StyliticsMiddleware,
];

export default rootMiddleware;
