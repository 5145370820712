import { fork, all } from 'redux-saga/effects';
import siteComponentSagas from 'yoda-site-components/lib/sagas';
import watchBreadCrumbNavRequest from 'yoda-site-components/lib/sagas/BreadCrumbNavSaga';
import watchCouponListSaga from 'yoda-site-components/lib/sagas/CouponListSaga';
import watchcartItemUpdateSaga from 'yoda-checkout-components/lib/sagas/CartItemUpdateSaga';
import watchAddToCartInterstitialSaga from 'yoda-checkout-components/lib/sagas/AddToCartInterstitialSaga';

const siteComponentSagaList = Object.values(siteComponentSagas.injectedSagas);
const productSagas = [
    watchCouponListSaga,
    /**
     * IRIS component breadcrumbs
     * yoda-site-components/src/components/BreadCrumbNav/BreadCrumbNav.jsx
     */
    watchBreadCrumbNavRequest,
    /**
     *  ATC mini PDP
     *  yoda-checkout-componentssrc/components/AddToCartInterstitial/AddToCartInterstitial.jsx
     */
    watchcartItemUpdateSaga,
    watchAddToCartInterstitialSaga,
    ...siteComponentSagaList,
];

const injectedSagas = {};
for (const saga of productSagas) { // eslint-disable-line
    // 1. Check that every saga has a name
    if (!saga.sagaName) {
        throw new Error(
            `Every saga has to have sagaName property. For debug purposes: ${saga.toString()}`
        );
    }

    // 2. Check that we have only unique sagas (one could be from components, another the same one from shared)
    if (injectedSagas[saga.sagaName]) {
        throw new Error(
            `That saga ${saga.sagaName} was already registered. Only 1 instance for particular name is allowed`
        );
    }
    injectedSagas[saga.sagaName] = saga;
}

const productRootSaga = function* productRootSaga() {
    yield all(Object.values(injectedSagas).map((saga) => fork(saga)));
};

productRootSaga.injectedSagas = injectedSagas;

export default productRootSaga;
