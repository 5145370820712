import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';
import constants from './Analytics.config';

class AnalyticsActionFilter extends BaseActionFilter {
    getHomepageDL(store) {
        const stateObj = store.getState();
        let deviceType = 'desktop';
        if (!stateObj.context.deviceType.isDesktop) {
            deviceType = stateObj.context.deviceType.isMobile ? 'mobile' : 'tablet';
        }

        const homepageDL = {
            page: {
                category: {
                    subCategory2: '',
                    subCategory1: '',
                    primaryCategory: constants.siteSection.HOME,
                    department: constants.siteSection.HOME,
                },
                pageInfo: {
                    pageType: constants.HOME_PAGE_ID, // home | department | category | gallery | search | pdp | customer service | promotion
                    siteSection: constants.siteSection.HOME, // home | men | women | for the home | search | gift-card
                    searchRedirect: this.getURLParameterBykey('redirectTerm') || '',
                    deviceType,
                    releaseVersion: stateObj.context.__RELEASEVERSION__, // eslint-disable-line
                    version: `apollo 1 ${constants.HOME_PAGE_ID}`,
                },
            },
        };

        return homepageDL;
    }

    getDepartmentDL(store) {
        const stateObj = store.getState();
        let deviceType = 'desktop';
        if (!stateObj.context.deviceType.isDesktop) {
            deviceType = stateObj.context.deviceType.isMobile ? 'mobile' : 'tablet';
        }
        const categoryObj = {};
        /* istanbul ignore next */
        if (stateObj.irisData && stateObj.irisData.analytics) {
            const analyticsData = stateObj.irisData.analytics;
            categoryObj.department = analyticsData.pageName;
            categoryObj.primaryCategory = analyticsData.pageName;
            categoryObj.subCategory1 = '';
            categoryObj.subCategory2 = '';
        }

        if (stateObj?.cmData?.page?.analytics) {
            const cmAnalyticsData = stateObj.cmData.page.analytics;
            categoryObj.department = cmAnalyticsData.pageName;
            categoryObj.primaryCategory = cmAnalyticsData.pageName;
            categoryObj.subCategory1 = '';
            categoryObj.subCategory2 = '';
        }
        const departmentDL = {
            page: {
                category: categoryObj,
                pageInfo: {
                    pageType: constants.DEPARTMENT_PAGE_ID, // home | department | category | gallery | search | pdp | customer service | promotion
                    siteSection: categoryObj.primaryCategory, // home | men | women | for the home | search | gift-card
                    pageName: constants.siteSection.DEPARTMENT,
                    searchRedirect: this.getURLParameterBykey('redirectTerm') || '',
                    deviceType,
                    releaseVersion: stateObj.context.__RELEASEVERSION__, // eslint-disable-line
                    version: `apollo 1 ${constants.DEPARTMENT_PAGE_ID}`,
                },
            },
        };

        return departmentDL;
    }

    getFindAStoreDL(store) {
        const stateObj = store.getState();
        let deviceType = 'desktop';
        if (!stateObj.context.deviceType.isDesktop) {
            deviceType = stateObj.context.deviceType.isMobile ? 'mobile' : 'tablet';
        }

        const findAStoreDL = {
            page: {
                attributes: {},
                category: {},
                pageInfo: {
                    pageType: constants.FINDASTORE_PAGE_ID, // home | department | category | gallery | search | pdp | customer service | promotion
                    siteSection: constants.siteSection.FINDASTORE, // home | men | women | for the home | search | gift-card
                    searchRedirect: this.getURLParameterBykey('redirectTerm') || '',
                    deviceType,
                    releaseVersion: stateObj.context.__RELEASEVERSION__, // eslint-disable-line
                    version: '2017 yoda findastore',
                },
            },
        };

        return findAStoreDL;
    }
}

export default AnalyticsActionFilter;
