/*
  -------------------------------
  DO NOT EDIT THIS FILE
  -------------------------------
*/
import 'yoda-app-shell/lib/assets/favicon.ico';
import client from 'yoda-app-shell/lib/client';
import context from './bootstrap';

window.jcpDLjcp = {};

client(context);
