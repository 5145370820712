import SiteComponentReducers from 'yoda-site-components/lib/reducers';
import BreadCrumbNavReducer from 'yoda-site-components/lib/reducers/BreadCrumbNavReducer';
import CouponListReducer from 'yoda-site-components/lib/reducers/CouponListReducer';
import CouponDrawerReducer from 'yoda-site-components/lib/reducers/CouponDrawerReducer';
import LoadingReducer from 'yoda-site-components/lib/reducers/LoadingReducer';
import MessageContentReducer from 'yoda-checkout-components/lib/reducers/MessageContentReducer';
import WebvitalsReducer from 'yoda-site-components/lib/reducers/WebvitalsReducer';

const rootReducer = {
    couponList: CouponListReducer,
    couponDrawer: CouponDrawerReducer,
    /**
     * bread crumbs IRIS component
     * yoda-site-components/src/iris/wrappers/BreadCrumbsNav/BreadCrumbsNav.jsx
     */
    breadCrumbsData: BreadCrumbNavReducer,
    /**
     * full screen loading
     * yoda-microsite-coupon/src/containers/Pages/Coupon/Coupon.jsx
     * yoda-microsite-coupon/src/helper/MiniPDPHelper.jsx
     * yoda-microsite-homepage/src/helper/MiniPDPHelper.jsx
     * yoda-microsite-marketing/src/helper/renderMiniPDP.jsx
     * yoda-microsite-pdp/src/components/AddToCart/AddToCart.jsx
     * yoda-microsite-pdp/src/components/Bundle/BundleAddToCart/BundleAddToCart.jsx
     * yoda-microsite-pdp/src/components/GeneralInformation/GeneralInformation.jsx
     * yoda-microsite-pdp/src/components/ImageZoom/ImageZoom.jsx
     * yoda-microsite-listing/src/common/CustomAddToCart/CustomAddToCart.jsx
     */
    isLoading: LoadingReducer,
    messageContentData: MessageContentReducer,
    webvitals: WebvitalsReducer,
    ...SiteComponentReducers,
};

export default rootReducer;
