export default (rowData, columns) => {
    const bottomComponentTypes = ['seoblock', 'recommendations', 'ads'];
    const newColumns = [];

    columns.forEach((col) => {
        if (col.zone === 'S2') {
            const { components } = col;

            // separate out seoblock, recs, ads to it's own section from page components
            const pageComponents = components.filter(
                (component) => !bottomComponentTypes.includes(component.componentType)
            );
            newColumns.push({ ...col, components: pageComponents });

            const bottomComponents = components.filter((component) =>
                bottomComponentTypes.includes(component.componentType)
            );
            if (bottomComponents.length !== 0) {
                newColumns.push({
                    ...col,
                    components: bottomComponents,
                    zoneDisplayName: `${col.zoneDisplayName}-b`,
                    zone: `${col.zone}-b`,
                });
            }
        } else {
            newColumns.push(col);
        }
    });

    const newRowData = { ...rowData, columns: newColumns };

    return newRowData;
};
