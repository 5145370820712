/* istanbul ignore next */
export const staticSuffix = 'home'; // eslint-disable-line
export const micrositeName = 'yoda-microsite-home';
export const cachePolicy = {
    version: 1,
    enabled: true,
    minCache: 86400, // Default 30 mins
    routeCache: {
        // Not supported yet
        match: '/g',
        minCache: 0,
        enabled: false,
    },
};

export const app = {
    logTag: 'MICROSITE:HOME-DEPT',
};
export const configServerSettingsFlag = true;
export const esiHeaderRequiredRoutes = null;
export const isContextHeadersRequired = true;
