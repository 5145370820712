import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from 'yoda-site-components/lib/components/Layout/Layout';
import Department from './containers/Pages/Department/Department';
import Home from './containers/Pages/Home/Home';
import FindaStore from './containers/Pages/FindaStore/FindaStore';

export default function App() {
    return (
        <>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/index.jsp" component={Home} />
                    <Route path="/g/:department/:ntype" component={Department} />
                    <Route path="/d/*" component={Department} />
                    <Route path="/findastore" component={FindaStore} />
                    <Route path="/mobile/g/:department/:ntype" component={Department} />
                    <Route path="/tablet/g/:department/:ntype" component={Department} />
                    <Route path="/m/jcpenney-coupons/N-mpi6e5" component={Department} />
                </Switch>
            </Layout>
        </>
    );
}
