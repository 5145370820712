import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import IrisPage from 'yoda-site-components/lib/components/IrisPage/IrisPage';
import ScrollTop from 'yoda-site-components/lib/components/ScrollTop/ScrollTop';
import { hotdealsPostProcessor } from 'yoda-interfaces/lib/IrisPage/IrisPage';
import irisComponentsForDept from 'yoda-site-components/lib/iris/components/forDepartment';
import CMPageLazy from 'yoda-site-components/lib/components/CoreMediaPage/CMPageLazy';
import CMPageAction from 'yoda-site-components/lib/actions/CMPageAction';
import RedirectAction from 'yoda-app-shell/lib/action/RedirectAction';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { selectIrisData } from 'yoda-site-components/lib/selectors/IrisPageSelector';
import { selectCMSwitchWidgetData } from 'yoda-site-components/lib/selectors/CMSwitchWidgetSelector';
import {
    selectCMData,
    selectIsIrisFallBackEnabled,
    selectCmPageInclusionList,
} from 'yoda-site-components/lib/selectors/CMPageSelector';
import {
    selectContext,
    selectDeviceName,
    isPreview as selectIsPreview,
    selectIrisFallbackPreferences,
    selectFeatureFlags,
    selectSeoProdHostName,
    selectRequestUrl,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import SeoSchema from 'yoda-site-components/lib/components/SeoSchema/SeoSchema';
import { getIRISS1Targeter } from 'yoda-site-components/lib/actions/IRISS1TargeterAction';
import { onPageReady } from '../../../actions/DepartmentAction';
import renderMiniPDP from '../../../helper/MiniPDPHelper';
import flexTwoColsZoneOverride from '../../../iris/zoneOverrides/flexTwoCols';
import cmTwoColsZoneOverride from '../../../CoredMedia/zoneOverrides/cmFlexTwoCols';

const defaultPreferences = {
    renderingStrategy: {
        dept: {
            limitInitialComponentsRendered: {
                mobile: 8,
                desktop: 9,
                tablet: 8,
            },
            offset: {
                mobile: 500,
                desktop: 800,
                tablet: 500,
            },
        },
    },
};
// eslint-disable-next-line react/no-redundant-should-component-update
export class DepartmentPage extends PureComponent {
    static defaultProps = {
        actions: {},
        irisData: {},
        cmData: {},
        params: {},
        location: {},
        isPreview: false,
        offset: defaultPreferences.renderingStrategy.dept.offset,
        limitInitialComponentsRendered:
            defaultPreferences.renderingStrategy.dept.limitInitialComponentsRendered,
        deviceName: 'mobile',
        seoProdHostName: 'https://www.jcpenney.com',
        enableSeoDepartmentSchema: true,
        irisFallbackEnabled: false,
        cmFallbackEnabled: true,
        isIrisFallBackEnabled: false,
        cmSwitchWidgetSelectedValue: '',
        enableCmPage: false,
        requestUrl: '',
        cmPageinclusionList: [],
        history: {},
    };

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func),
        irisData: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ])
        ),
        params: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
        location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
        isPreview: PropTypes.bool,
        // when using react rendering strategies use this props to pass height and offset to ref div
        offset: PropTypes.number,
        deviceName: PropTypes.string,
        limitInitialComponentsRendered: PropTypes.string,
        seoProdHostName: PropTypes.string,
        enableSeoDepartmentSchema: PropTypes.bool,
        irisFallbackEnabled: PropTypes.bool,
        cmFallbackEnabled: PropTypes.bool,
        isIrisFallBackEnabled: PropTypes.bool,
        cmSwitchWidgetSelectedValue: PropTypes.string,
        enableCMSwitchWidget: PropTypes.bool.isRequired,
        enableMiniPDPFeature: PropTypes.bool.isRequired,
        cmData: PropTypes.object,
        enableCmPage: PropTypes.bool,
        requestUrl: PropTypes.string,
        cmPageinclusionList: PropTypes.array,
        cmFallbackPreferences: PropTypes.object.isRequired,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.departmentNavHeight = null;
        this.departmentS2Height = null;
        this.state = {
            departmentLowerZoneSm: true,
        };
    }

    componentDidMount() {
        const {
            irisData,
            requestUrl,
            enableCmPage,
            cmData,
            isIrisFallBackEnabled,
            cmPageinclusionList,
            location,
            isPreview,
            enableCMSwitchWidget,
            cmSwitchWidgetSelectedValue,
            actions,
        } = this.props;
        const params = location.pathname;
        const isCMTestEnabled = new URLSearchParams(location?.search).get('cmTest');
        let isCMPageEnabled =
            isPreview && enableCMSwitchWidget
                ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                  !isIrisFallBackEnabled &&
                  (isCMTestEnabled === 'true' || enableCmPage)
                : !isIrisFallBackEnabled &&
                  (isCMTestEnabled === 'true' ||
                      (enableCmPage && cmPageinclusionList?.includes(params)));

        isCMPageEnabled = isCMTestEnabled === 'false' ? false : isCMPageEnabled;

        /* istanbul ignore next */
        if (!_isEmpty(irisData)) {
            const { analytics } = irisData;
            if (analytics) {
                const { pageType, pageName } = analytics;
                actions?.onPageReady(pageName, pageType);
            }
        } else if (isCMPageEnabled) {
            const cmAnalytics = cmData?.page?.analytics;
            if (cmAnalytics) {
                const { pageType, pageName } = cmAnalytics;
                actions?.onPageReady(pageName, pageType);
            }
        }

        const cmRowData = _get(cmData, 'page.grid.rows', []);
        const isCMComponentDataEmpty =
            cmRowData?.length > 0 ? this.checkCMDataAvailable(cmRowData) : false;

        const isS1CMDataEmpty =
            cmRowData?.length > 0 ? this.checkS1CMDataAvailable(cmRowData) : true;

        LocalStorage.removeData('scrollToCartridge');
        LocalStorage.removeData('galleryScrollTo');

        if (
            !isIrisFallBackEnabled &&
            (isCMPageEnabled || cmSwitchWidgetSelectedValue === 'core Media') &&
            !isCMComponentDataEmpty
        ) {
            if (isS1CMDataEmpty) {
                actions?.getIRISS1Targeter(requestUrl);
            }
        }
        /* check for height changes every 1 sec for 10 sec */
        const checkHeightInterval = setInterval(this.handleCheckHeightChange, 1000);
        setTimeout(() => clearInterval(checkHeightInterval), 10000);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps?.isIrisFallBackEnabled) {
            return true;
        }
        if (nextProps?.cmSwitchWidgetSelectedValue !== this?.props?.cmSwitchWidgetSelectedValue) {
            return true;
        }
        if (nextState?.departmentLowerZoneSm !== this?.state?.departmentLowerZoneSm) {
            return true;
        }
        if (nextProps.irisData?.id && nextProps.irisData.id !== this.props.irisData?.id) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.irisData?.id !== this.props.irisData?.id) {
            const { analytics } = this.props.irisData;
            if (analytics) {
                const { pageType, pageName } = analytics;
                this.props.actions.onPageReady(pageName, pageType);
            }
        }
    }

    handleDepartmentPage2ColCallback = (element) => {
        const id = element?.dataset?.automationId;
        if (id === 'zone-Navigation' || id === 'zone-LHN') this.departmentNavHeight = element;
        if (id === 'zone-S2') this.departmentS2Height = element;

        this.handleCheckHeightChange();
    };

    handleCheckHeightChange = () => {
        // if height is within 50px difference, go ahead and make main content full width
        if (this?.departmentNavHeight?.clientHeight > this?.departmentS2Height?.clientHeight + 50) {
            this.setState({
                departmentLowerZoneSm: true,
            });
        } else {
            this.setState({
                departmentLowerZoneSm: false,
            });
        }
    };

    transformBreadCrumbsData = (breadcrumbs, seoUrl, isCmData = true) => {
        let breadCrumbsArr = [];
        const { seoProdHostName } = this.props;

        /* istanbul ignore else */
        if (breadcrumbs?.length) {
            breadCrumbsArr = breadcrumbs.map((val, index) => {
                let breadCrumbLabel = '';
                let pageUrl = '';
                let itemURL = '';

                if (isCmData) {
                    // cm data
                    breadCrumbLabel = val?.breadCrumbLabel || '';
                    pageUrl = val?.pageUrl || '';
                } else {
                    // iris data
                    breadCrumbLabel = val?.linkText || '';
                    pageUrl = val?.link?.url || '';
                }

                if (pageUrl === '/') {
                    itemURL = seoProdHostName;
                } else if (_isEmpty(pageUrl)) {
                    itemURL = `${seoProdHostName}${seoUrl}`;
                } else {
                    itemURL = `${seoProdHostName}${pageUrl}`;
                }

                const breadcrumbsObj = {
                    '@type': 'ListItem',
                    position: index + 1,
                    item: {
                        '@id': itemURL,
                        '@type': 'Thing',
                        name: breadCrumbLabel,
                    },
                };
                return breadcrumbsObj;
            });
        }
        return breadCrumbsArr;
    };

    buildSchema = (data, iscmdata) => {
        const { seoProdHostName, cmData, irisData } = this.props;
        let breadcrumbs = [];
        let seoUrl = '';
        let seoPageTitle = '';
        let breadCrumbsTransformed = [];

        if (iscmdata && cmData?.page) {
            // CmData scheme
            breadcrumbs = _get(cmData, 'page.breadcrumbPath', []);
            seoUrl = _get(cmData, 'seoMetaData.canonicalUrl', '');
            seoPageTitle = _get(data, 'seoMetaData.pageTitle', '');
            breadCrumbsTransformed = this.transformBreadCrumbsData(breadcrumbs, seoUrl);
        } else {
            // Iris Data scheme
            breadcrumbs = _get(irisData, 'taxonomyData.breadcrumbsData', []);
            seoUrl = _get(irisData, 'taxonomyData.seoUrl', '');
            seoPageTitle = _get(irisData, 'seoMetaData.content.pageTitle', '');
            breadCrumbsTransformed = this.transformBreadCrumbsData(breadcrumbs, seoUrl, false);
        }

        const isInHeadTag = true;
        const schema = {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            name: seoPageTitle,
            url: `${seoProdHostName}${seoUrl}`,
            breadcrumb: {
                '@type': 'BreadcrumbList',
                itemListElement: breadCrumbsTransformed,
            },
        };
        return <SeoSchema isInHeadTag={isInHeadTag} schema={schema} />;
    };

    checkCMDataAvailable = (cmRowData = []) => {
        // Only check S2 is empty for department page
        let isPlacementDataEmpty = false;
        cmRowData?.forEach((row) => {
            row?.placements?.forEach((plcmt) => {
                if (plcmt?.name === 'S2' && plcmt?.items?.length === 0) {
                    isPlacementDataEmpty = true;
                }
            });
        });

        return isPlacementDataEmpty;
    };

    checkS1CMDataAvailable = (cmRowData = []) => {
        // Only check S2 is empty for department page
        let isPlacementDataEmpty = false;
        cmRowData?.forEach((row) => {
            row?.placements?.forEach((plcmt) => {
                if (plcmt?.name === 'S1' && plcmt?.items?.length === 0) {
                    isPlacementDataEmpty = true;
                }
            });
        });

        return isPlacementDataEmpty;
    };

    render() {
        const patt = new RegExp('/d/'); // Regex to check the URL
        const {
            cmFallbackPreferences,
            location,
            location: { pathname = '', search = '' },
            history,
            limitInitialComponentsRendered,
            offset,
            deviceName,
            irisData,
            enableSeoDepartmentSchema,
            isPreview,
            enableCMSwitchWidget,
            cmSwitchWidgetSelectedValue,
            isIrisFallBackEnabled,
            enableCmPage,
            cmData,
            cmPageinclusionList,
            params,
            enableMiniPDPFeature,
        } = this.props; // getting location from router
        const { departmentLowerZoneSm } = this.state;
        let formattedUrl;
        /* istanbul ignore next */
        if (isPreview) {
            formattedUrl = `${pathname}${search}`;
        } else if (patt.test(pathname)) {
            formattedUrl = `${pathname}`;
        } else {
            formattedUrl = `/g/${params?.department}/${params?.ntype}?pageType=X2H2`;
        }

        const isCMTestEnabled = new URLSearchParams(search).get('cmTest');

        let isCMPageEnabled =
            isPreview && enableCMSwitchWidget
                ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                  !isIrisFallBackEnabled &&
                  (isCMTestEnabled === 'true' || enableCmPage)
                : !isIrisFallBackEnabled &&
                  (isCMTestEnabled === 'true' ||
                      (enableCmPage && cmPageinclusionList?.includes(pathname)));

        isCMPageEnabled = isCMTestEnabled === 'false' ? false : isCMPageEnabled;

        const cmRowData = _get(cmData, 'page.grid.rows', []);

        const isCMComponentDataEmpty =
            cmRowData?.length > 0 ? this.checkCMDataAvailable(cmRowData) : false;

        const renderMiniPDPComponent = enableMiniPDPFeature ? renderMiniPDP() : <></>;

        const irisPageProps = {
            params: formattedUrl,
            irisFallbackEnabled: isIrisFallBackEnabled,
            irisComponents: irisComponentsForDept,
            'data-automation-id': 'department',
            postProcessor: hotdealsPostProcessor,
            limitInitialComponentsRendered: limitInitialComponentsRendered[deviceName],
            offset: offset[deviceName],
            isFallingBackAfterCMFailed: isIrisFallBackEnabled,
            zoneRenderer: [
                {
                    zone: 'flex-two-cols',
                    renderer: flexTwoColsZoneOverride,
                },
            ],
        };

        const cmPageProps = {
            params: pathname,
            irisComponentsData: irisComponentsForDept,
            irisS1Enable: true,
            cmFallbackPreferences,
            redirectAction: RedirectAction?.setRedirectUrl,
            isCMPageEnabled: isCMPageEnabled || cmSwitchWidgetSelectedValue === 'core Media',
            location,
            history,
            zoneRenderer: [
                {
                    zone: 'flex-two-cols',
                    renderer: cmTwoColsZoneOverride,
                },
            ],
        };

        const commonProps = {
            handleDepartmentPage2ColCallback: this.handleDepartmentPage2ColCallback,
            departmentLowerZoneSm,
            pageType: 'DEPARTMENT',
            aggregateAPI: true,
        };

        return (
            <>
                <ScrollTop />
                {!_isEmpty(irisData) && enableSeoDepartmentSchema && this.buildSchema(irisData)}
                {!_isEmpty(cmData) && enableSeoDepartmentSchema && this.buildSchema(cmData, true)}

                {!isIrisFallBackEnabled &&
                (isCMPageEnabled || cmSwitchWidgetSelectedValue === 'core Media') &&
                !isCMComponentDataEmpty ? (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <CMPageLazy {...cmPageProps} {...commonProps} />
                ) : (
                    (!isCMPageEnabled ||
                        isIrisFallBackEnabled ||
                        cmSwitchWidgetSelectedValue === 'IRIS' ||
                        cmSwitchWidgetSelectedValue === '') && (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <IrisPage {...irisPageProps} {...commonProps} />
                    )
                )}
                {renderMiniPDPComponent}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const context = selectContext(state);
    const featureFlags = selectFeatureFlags(state);
    return {
        cmData: selectCMData(state),
        irisData: selectIrisData(state),
        cmSwitchWidgetSelectedValue: selectCMSwitchWidgetData(state)?.cmSwitchWidgetSelectedValue,
        isPreview: selectIsPreview(state),
        cmFallbackPreferences: selectIrisFallbackPreferences(state),
        cmPageinclusionList: selectCmPageInclusionList(state),
        seoProdHostName: selectSeoProdHostName(state),
        isIrisFallBackEnabled: selectIsIrisFallBackEnabled(state),
        requestUrl: selectRequestUrl(state),
        irisFallbackEnabled: _get(featureFlags, 'enableIrisFallback', true),
        disableIrisCall: _get(featureFlags, 'disableIrisCall', false),
        enableCmPage: _get(featureFlags, 'enableCmPage', false),
        cmFallbackEnabled: _get(featureFlags, 'enableIrisFallback', true),
        enableSeoDepartmentSchema: _get(featureFlags, 'enableSeoDepartmentSchema', true),
        enableCMSwitchWidget: _get(featureFlags, 'enableCMSwitchWidget', false),
        enableMiniPDPFeature: _get(featureFlags, 'enableMiniPDPFeature', true),
        deviceName: selectDeviceName({ context }),
        limitInitialComponentsRendered: _get(
            context,
            'preferences.renderingStrategy.home.limitInitialComponentsRendered',
            defaultPreferences.renderingStrategy.dept.limitInitialComponentsRendered
        ),
        offset: _get(
            context,
            'preferences.renderingStrategy.home.offset',
            defaultPreferences.renderingStrategy.dept.offset
        ),
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            onPageReady,
            getIRISS1Targeter,
            ...CMPageAction,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentPage);
